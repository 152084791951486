import styled from "styled-components";

import { COLORS } from "../../styles/globals/colors";
import { DESKTOP_1280, TABLET_600, TABLET_992 } from "../../styles/globals/sizes";

export const Container = styled.div`
  background: transparent linear-gradient(270deg, #E01A73 0%, #E0281A 100%) 0% 0% no-repeat padding-box;
  display: flex;
  flex-direction: row;
  background-size: cover;
  align-items: center;
  flex: 1;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${TABLET_992}px) {
    flex-direction: column;
    align-items: center;
    padding: 10px 0;
    background: ${COLORS.white};

    &:nth-child(4) {
        padding-top: 0;
        flex-direction: column-reverse;
    }
}
`;

export const ImageContainer = styled.div<{height: string}>`
  width: 50%;
  height: ${({height}) => height}px;

  @media (max-width: ${TABLET_992}px) {
    width: 100%;
    height: 100%;
  }

  @media (max-width: ${TABLET_600}px) {
    img {
      height: auto;
      object-fit: contain !important;
    }
  }
`;

export const TextContainer = styled.div`
  width: 50%;
  height: 576px;
  padding: 55px 61px 21px 42px;

  @media (max-width: ${DESKTOP_1280}px) {
    height: 100%;
  }

  @media (max-width: ${TABLET_992}px) {
    width: 100%;
    padding: 40px 20px 29px;
  }
`;

export const Title = styled.h2`
  font-size: 38px;
  line-height: 50px;
  font-family: 'Roboto';
  color: ${COLORS.white};

  @media (max-width: ${TABLET_992}px) {
    width: 80%;
    margin: 0 auto 40px;
    text-align: center;
    font-weight: bold;
    font-size: 38px;
    line-height: 50px;  
    color: ${COLORS.vermilion};

  }
`;

export const Description = styled.p`
  font-size: 20px;
  line-height: 26px;
  font-family: 'Roboto';
  color: ${COLORS.white};

  strong {
    font-weight: bold;
  }

  @media (max-width: ${TABLET_992}px) {
    font-size: 22px;
    line-height: 28px;
    color: ${COLORS.grayText};
  }
`;


export const WhiteLine = styled.div`
  width: 149px;
  height: 4px;
  margin: 10px 0 20px;
  background-color: ${COLORS.white};

  @media (max-width: ${TABLET_992}px) {
    display: none;
  }
`;
