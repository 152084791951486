import styled from "styled-components";
import { DESKTOP_1024, DESKTOP_1200, DESKTOP_1280, DESKTOP_1500, MOBILE_460, TABLET_992 } from "../../styles/globals/sizes";
import { COLORS } from "../../styles/globals/colors";

export const HeroContainer = styled.div`
  width: 100%;
  margin: 90px auto 0 auto;
  object-fit: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  background: #ffffff;
  position: relative;

  @media (max-width: ${DESKTOP_1024}px) {
    height: fit-content;
  }

  @media (max-width: ${MOBILE_460}px) {
    margin-top: 80px;
  }
`;

export const HeroPicture = styled.picture`
  & > img,
  & > source {
    width: 100%;
    margin-right: 0%;
    object-fit: cover;
  }
  @media (max-width: ${TABLET_992}px) {
    display: none;
  }
`;

export const InternalWrapper = styled.div`
  width: 100%;
  margin: 0;
  position: absolute;

  @media (max-width: ${DESKTOP_1500}px) {
    bottom: 15%;
  }

  @media (max-width: ${DESKTOP_1200}px) {
    max-width: 90%;
    bottom: 10%;
  }

  @media (max-width: ${TABLET_992}px) {    
    position: unset;
  }

`;

export const HeroPictureMobile = styled.div`
  width: 100%;
  display: none;

  @media (max-width: ${TABLET_992}px) {
    display: contents;
  }
`;

export const HeroTextDiv = styled.div`
  width: 100%;
  margin-left: 60px;
  box-sizing: border-box;

  @media (max-width: ${TABLET_992}px) {
    margin: 30px 20px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
`;

export const Title = styled.h1`
  width: 33%;
  text-align: left;
  font: normal normal 500 40px/47px Roboto;
  color: #E0281A;
  text-transform: uppercase;

  & > .nowrap {
    white-space: nowrap;
  }

  @media (max-width: ${DESKTOP_1280}px) {
    width: 42%;
  }

  @media (max-width: 1100px) {
    width: 60%;
  }

  @media (max-width: 1045px) {
    font-size: 38px;
    line-height: 42px;
  }

  @media (max-width: ${TABLET_992}px) {
    width: 100%;
    font: normal normal normal 40px/48px 'Roboto';
    align-items: center;
  }
`;

export const PhoneAndParagraph = styled.div`
  @media (max-width: ${TABLET_992}px) {
    display: flex;
    flex-direction: column-reverse;
  }
`;


export const Paragraph = styled.p`
  width: 41%;
  margin-top: 20px;
  font: normal normal normal 20px/28px 'Roboto';
  
  p {
    color: ${COLORS.grayText} !important;
  }

  @media (max-width: ${DESKTOP_1200}px) {
    width: 53%;
  }

  @media (max-width: ${TABLET_992}px) {
    width: 100%;
    margin: 0;
    font: normal normal normal 22px/28px 'Roboto';
  }
`;