import React from "react";

import { CONTACT } from "../../config/urls";

import Phone from "../../images/phone-call.svg";

import {
  ContactUsContainer,
  ContactUsext,
  PhoneIcon,
  ContactUsButton,
  ContactUsDiv,
  SmallText,
  TextSection,
  BoldText,
} from "./ContactUs.styles";

const ContactUs = () => {
  return (
    <ContactUsContainer>
      <div data-aos="fade" className="container">
        <ContactUsDiv id="contact_us">
          <TextSection>
            <PhoneIcon src={Phone} alt="phone icon" />
            <ContactUsext>
              To find out more about our services
              <SmallText>
                Call us at 
                <BoldText>(973) 273-3441</BoldText>
                for in-home care services. At LIFE HOMECARE, we’re dedicated to delivering excellence and compassion. Discover more about our experienced home care services today.</SmallText>
            </ContactUsext>
          </TextSection>
            <ContactUsButton href={`mailto:${CONTACT.MAIL_CONTACT_US_LOCATIONS}`}>
              Contact Us
            </ContactUsButton>
        </ContactUsDiv>
      </div>
    </ContactUsContainer>
  );
};

export default ContactUs;
