import React, {useState, useEffect} from "react";
import MediaRender from "../Common/MediaRender";
import Markdown from "../Common/Markdown";
import { 
  Container, 
  ImageContainer,
  TextContainer,
  Title,
  Description,
  WhiteLine,
 } from "./townshipDescription.styles";

const TownshipDescription = (props) => {
  const [heightImage, setHeightImage] = useState('');

  useEffect(() => {
    const handleResize = () => {
      const textContainer = document.getElementById('text-container');
      setHeightImage(textContainer.offsetHeight);
      
    };
    window.addEventListener('resize', handleResize);
  }, []);

  return (
    <Container>
      <ImageContainer height={heightImage}>
        <MediaRender mediaSource={props.image} />
      </ImageContainer>
      <TextContainer id="text-container">
        <Title>
          <Markdown
            text={props.title}
            textColor="white"
          />
        </Title>
        <WhiteLine />
        <Description>
          <Markdown
            text={props.description}
            textColor="white"
          />
        </Description>
      </TextContainer>
    </Container>
  );
};

export default TownshipDescription;
