import React from "react";
import { graphql } from "gatsby";

import seoImage from "../../static/seo/seo_image.png";

import Seoinfo from "./seoinfo/seoinfo";
import Layout from "./layout";
import transformToSlug from "./utils";
import ContactUs from "./ContactUs/ContactUs";
import TownshipDescription from "./TownshipDescription/TownshipDescription";
import OurCareServices from "./OurCareServices/OurCareServices";
import TownshipHero from "./TownshipHero/TownshipHero";

const TownshipTemplate = ({ data, pageContext }: any) => {
  const TownshipData = data?.cms?.locationsTownshipPage?.data?.attributes;  
  const TownshipUrl = `/locations/${pageContext.county}/home-care-in-${transformToSlug(TownshipData.locations_township?.data?.attributes?.Township)}`;
  
  return (
    <main>
      <Layout>
        <Seoinfo
            siteName={`Explore Life Homecare in ${TownshipData.locations_township?.data?.attributes?.Township} for Quality Services`}
            titlePage={`Explore Life Homecare in ${TownshipData.locations_township?.data?.attributes?.Township} for Quality Services`}
            description={`Discover top-notch Life Homecare services in ${TownshipData.locations_township?.data?.attributes?.Township}. Get the care you deserve today!`}
            keywords={"Home, Care, HomeCare, Senior, Compassionate, Care, Home Care Agency, Home Care Agencies"}
            type={"website"}
            url={TownshipUrl}
            imageFacebook={seoImage}
          />
          <TownshipHero title={TownshipData.Hero_Title} subtitle={TownshipData.Hero_Subtitle}/>
          <OurCareServices />
          <TownshipDescription 
            title={TownshipData.Township_Description.Title} 
            description={TownshipData.Township_Description.Description} 
            image={TownshipData.Township_Description.Image}
          />
          <ContactUs />
      </Layout>
    </main>
  );
};

export const pageQuery = graphql`
  query($id: ID!) {
    cms {
        locationsTownshipPage(id: $id) {
        data {
          attributes {
            locations_township {
              data {
                attributes {
                  Township
                }
              }
            }
            Hero_Title
            Hero_Subtitle
            Township_Description {
              Title
              Description
              Image {
                ...Media
              }
            }
          }
        }
      }
    }
  }
`;

export default TownshipTemplate;
